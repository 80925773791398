import React, { useState, useEffect, createContext, useCallback } from 'react'
import './eui_theme_light.css'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'
import {
  useQueryParam,
  StringParam,
  ArrayParam,
  ObjectParam,
  withDefault,
} from 'use-query-params'
import { EuiGlobalToastList } from '@elastic/eui'

import uniq from 'array-uniq'
import merge from 'lodash.merge'
import './app.css'
import {
  NavBar,
  Header,
  DashboardSwitch,
  ProjectsPage,
  NotificationsPage,
  AdminPage,
  SettingsPage,
  GroupsPage,
  ToolBar,
  ErrorBoundary,
  ErrorMessage,
} from './components'
import {
  Toaster,
  api,
} from './lib'
import {
  mergeGroupedNotifications,
  notificationTypeMap,
} from './components/notifications/utils'
import Keycloak from './lib/keycloak'
import packageInfo from '../package.json'
import { useLocalStorage } from './lib/hooks/useLocalStorage'

import moment from 'moment'

const wsHost = process.env.REACT_APP_WS_HOST

const getDefaultDate = () => {
  try {
    const localDate = window.localStorage.getItem('defaultDate')
    if (localDate) {
      return JSON.parse(localDate)
    }
  } catch (error) {
    console.log(error)
  }
  return ['now-24h', 'now']
}

export const AppContext = createContext()

function App() {
  const [query, setQuery] = useQueryParam('q', StringParam)
  const [date, setDate] = useQueryParam(
    'd',
    withDefault(ArrayParam, getDefaultDate())
  )
  const [highlightLines, setHighlightLines] = useQueryParam(
    'hl',
    withDefault(ArrayParam, [])
  )
  const [selectedStatuses, setselectedStatuses] = useQueryParam(
    'statuses',
    withDefault(ArrayParam, [])
  )
  const [selectedProjects, setSelectedProjects] = useQueryParam(
    'projects',
    withDefault(ArrayParam, [])
  )
  const [selectedUseCases, setSelectedUseCases] = useQueryParam(
    'useCases',
    withDefault(ArrayParam, [])
  )
  const [selectedSenders, setSelectedSenders] = useQueryParam(
    'senders',
    withDefault(ArrayParam, [])
  )
  const [selectedRecipients, setSelectedRecipients] = useQueryParam(
    'recipients',
    withDefault(ArrayParam, [])
  )
  const [selectedProjectData, setSelectedProjectData] = useState({})
  const [environment, setEnvironment] = useQueryParam(
    'env',
    withDefault(StringParam, 'Production')
  )
  const [entityTableSorts, setEntityTableSorts] = useState({
    projects: { field: 'name', direction: 'asc' },
    useCases: { field: 'name', direction: 'asc' },
    senders: { field: 'name', direction: 'asc' },
    recipients: { field: 'name', direction: 'asc' },
    eventTypes: { field: 'name', direction: 'asc' },
    warnings: { field: 'name', direction: 'desc' },
  })
  const [messagesTableSort, setMessagesTableSort] = useQueryParam(
    'ms',
    withDefault(ObjectParam, {
      field: 'MessageDateTime',
      direction: 'desc',
    })
  )
  const [selectedEventTypes, setSelectedEventTypes] = useQueryParam(
    'eventTypes',
    withDefault(ArrayParam, [])
  )
  const [selectedWarnings, setSelectedWarnings] = useQueryParam(
    'warnings',
    withDefault(ArrayParam, [])
  )
  const [users, setUsers] = useState([])
  const [tags, setTags] = useState([])
  const [projects, setProjects] = useState([])
  const [useCases, setUseCases] = useState([])
  const [senders, setSenders] = useState([])
  const [recipients, setRecipients] = useState([])
  const [eventTypes, setEventTypes] = useState([])
  const [warnings] = useState([
    {
      name: 'Structure Validation',
    },
    {
      name: 'Transcoding Failure',
    },
    {
      name: 'None',
    },
  ])
  const [notifications, setNotifications] = useState({})
  const [messages, setMessages] = useState([])
  const [groups, setGroups] = useState([])
  const [userGroups, setUserGroups] = useState([])
  const [messageCounts, setMessageCounts] = useState({
    total: {},
    projectCounts: {},
    useCaseCounts: {},
    senderCounts: {},
    subsenderCounts: {},
    recipientCounts: {},
    eventTypeCounts: {},
    warningCounts: {},
    previous: {
      total: {},
      projectCounts: {},
      useCaseCounts: {},
      senderCounts: {},
      subsenderCounts: {},
      recipientCounts: {},
      eventTypeCounts: {},
      warningCounts: {},
    },
  })
  const [loadingMessageCounts, setLoadingMessageCounts] = useState(true)
  const [authenticated, setAuthenticated] = useState(false)
  const [keycloak, setKeycloak] = useState({})
  const [loadingMessages, setLoadingMessages] = useState(false)
  const [loadingProjects, setLoadingProjects] = useState(false)
  const [loadingUseCases, setLoadingUseCases] = useState(false)
  const [loadingSenders, setLoadingSenders] = useState(false)
  const [loadingRecipients, setLoadingRecipients] = useState(false)
  const [loadingEventTypes, setLoadingEventTypes] = useState(false)
  const [loadingMessageCountsOverTime, setLoadingMessageCountsOverTime] =
    useState(false)
  const [messageCountsOverTime, setMessageCountsOverTime] = useState()
  const [projectsChecked, setProjectsChecked] = useState(
    !!selectedProjects.length
  )
  const [useCasesChecked, setUseCasesChecked] = useState(
    !!selectedUseCases.length
  )
  const [sendersChecked, setSendersChecked] = useState(!!selectedSenders.length)
  const [recipientsChecked, setRecipientsChecked] = useState(
    !!selectedRecipients.length
  )
  const [eventTypesChecked, setEventTypesChecked] = useState(
    !!selectedEventTypes.length
  )
  const [warningsChecked, setWarningsChecked] = useState(
    !!selectedWarnings.length
  )
  const [moreMessagesAvailable, setMoreMessagesAvailable] = useState(true)
  const [toasts, setToasts] = useState([])
  const [displayNames, setDisplayNames] = useState({
    projects,
    recipients,
    useCases,
    senders,
    eventTypes,
    warnings,
  })

  const [showNotificationSettings, setShowNotificationSettings] =
    useState(false)
  const [notificationsInitialSelected, setNotificationsInitialSelected] =
    useState([])
  const [notificationSettingsInitialOpen, setNotificationSettingsInitialOpen] =
    useState('')

  const [usersLastQtr, setUsersLastQtr] = useState(0)

  const initialPagination = {
    offset: 0,
    limit: 50,
    total: null,
  }
  const [usersPagination, setUsersPagination] = useState({
    ...initialPagination,
  })
  const [tagsPagination, setTagsPagination] = useState({
    ...initialPagination,
  })
  const [notificationsPagination, setNotificationsPagination] = useState({
    ...initialPagination,
  })
  const [notificationsPaginationLatest, setNotificationsPaginationLatest] =
    useState({
      ...initialPagination,
    })
  const [groupsPagination, setGroupsPagination] = useState({
    ...initialPagination,
  })

  const [notificationsSearch, setNotificationsSearch] = useState('')
  const [notificationsTableIdMap, setNotificationsTableIdMap] = useState({})
  const [notificationInitialSelect, setNotificationInitialSelect] = useState('')
  const [notificationsLoaded, setNotificationsLoaded] = useState(false)
  const [notificationsLoadedLatest, setNotificationsLoadedLatest] =
    useState(false)
  const [notificationSettings, setNotificationSettings] = useState({})
  const [notificationSettingsGlobal, setNotificationSettingsGlobal] = useState(
    {}
  )
  const [latestNotifications, setLatestNotifications] = useState([])

  const [navBarExpanded, setNavBarExpanded] = useState(false)

  const [csvExports, setCsvExports] = useState([])
  const [showToolBar, setShowToolBar] = useState(true)
  const [exportToolExpanded, setExportToolExpanded] = useState(false)
  const [supportToolExpanded, setSupportToolExpanded] = useState(false)
  const [supportTickets, setSupportTickets] = useState([])

  const [offlineStatus, setOfflineStatus] = useState({})

  const [ws, setWs] = useState(null)
  const [initPushNoti, setInitPushNoti] = useLocalStorage('initPushNoti', false)

  const history = useHistory()
  const isMainDashRoute = useLocation().pathname === '/'

  const userGuideUrl =
    'https://docs.google.com/document/d/e/2PACX-1vRJ9ROOsntIkIamcRUKgAt5kDAqBk-LnAUwnQepfZn5YixanVt7ez9jIxXw9com12E-wodrW5GIbCEl/pub'

  const handleNotificationPopout = (id) => {
    const newMap = {}
    newMap[String(id)] = true
    setNotificationsTableIdMap(newMap)
    setNotificationInitialSelect(id)
    history.push('/notifications')
  }

  Keycloak.onTokenExpired = () => {
    refreshAuth()
  }

  const initKeycloak = () => {
    return Keycloak.init({
      onLoad: 'login-required',
      checkLoginIframe: false,
    }).then((authenticated) => {
      setKeycloak(Keycloak)
      setAuthenticated(authenticated)
      if (Keycloak.hasClientRole({ roleName: 'etor-hco' })) {
        history.push('/projects/ETOR?projects=ETOR')
      }
    })
  }

  const refreshAuth = () => {
    Keycloak.updateToken(10)
      .then(() => setAuthenticated(true))
      .catch(() => setAuthenticated(false))
  }

  const toaster = Toaster(setToasts)

  const dismissToast = (toast) => {
    setToasts(toasts.filter((t) => t.id !== toast.id))
  }

  const clearMessages = () => {
    setMessages([])
  }

  const clearFilters = () => {
    if (selectedProjects.length) {
      setSelectedProjects([])
      setProjectsChecked(false)
    }
    if (selectedRecipients.length) {
      setSelectedRecipients([])
      setRecipientsChecked(false)
    }
    if (selectedUseCases.length) {
      setSelectedUseCases([])
      setUseCasesChecked(false)
    }
    if (selectedSenders.length) {
      setSelectedSenders([])
      setSendersChecked(false)
    }
    if (selectedStatuses.length) {
      setselectedStatuses([])
    }
    if (selectedEventTypes.length) {
      setSelectedEventTypes([])
      setEventTypesChecked(false)
    }
    if (selectedWarnings.length) {
      setSelectedWarnings([])
      setWarningsChecked(false)
    }
    if (Object.keys(selectedProjectData).length) {
      setSelectedProjectData({})
    }
  }

  const buildDisplayNames = (data) => {
    let newObject = {
      general: 'General',
    }
    data.forEach((entity) => {
      newObject[entity.name] = entity.displayName || entity.name
    })
    return newObject
  }

  const fetchUsers = useCallback(
    async (
      offset = usersPagination.offset,
      limit = usersPagination.limit,
      search = '',
      loadMore = false
    ) => {
      try {
        const result = await api.fetchUsers({ offset, limit, search })
        if (loadMore) {
          setUsers((prevState) => [...prevState, ...result.data.users])
        } else {
          setUsers(result.data.users)
        }
        if (result.data.pagination) {
          setUsersPagination((prevState) => ({
            ...prevState,
            total: result.data.pagination.rowCount,
            offset: result.data.pagination.offset,
          }))
        }
        if (result.data.lastQtrCount !== undefined) {
          setUsersLastQtr(result.data.lastQtrCount)
        }
      } catch (err) {
        toaster({
          color: 'danger',
          title: 'Unable to fetch users',
          text: err.message,
        })
      }
    },
    [toaster]
  )

  const fetchTags = useCallback(
    async (
      offset = tagsPagination.offset,
      limit = tagsPagination.limit,
      search = '',
      loadMore = false
    ) => {
      try {
        const result = await api.fetchTags({ offset, limit, search })
        if (loadMore) {
          setTags((prevState) => [...prevState, ...result.tags])
        } else {
          setTags(
            [...result.tags].map((object) => {
              return object
            })
          )
        }
        if (result.pagination) {
          setTagsPagination((prevState) => ({
            ...prevState,
            total: result.pagination.rowCount,
            offset: result.pagination.offset,
          }))
        } else {
          setTagsPagination(initialPagination)
        }
      } catch (err) {
        toaster({
          color: 'danger',
          title: 'Unable to fetch tags',
          text: err.message,
        })
      }
    },
    [toaster]
  )

  const getProjectList = (projects) => {
    let projectList = []
    projects.forEach(({ name }) => {
      projectList.push(name)
    })
    return projectList
  }

  const fetchNotificationSettings = useCallback(
    async (projects) => {
      const projectList = getProjectList(projects)
      try {
        const settings = await api.fetchNotificationSettings(projectList)
        const global = await api.fetchNotificationSettingsGlobal()
        setNotificationSettings(settings.data)
        setNotificationSettingsGlobal(global.data)
      } catch (err) {
        toaster({
          color: 'danger',
          title: 'Unable to fetch notification settings',
          text: err.message,
        })
      }
    },
    [toaster]
  )

  const fetchProjects = useCallback(async () => {
    setLoadingProjects(true)
    try {
      const result = await api.fetchProjects()
      setProjects(result.data)
      const names = buildDisplayNames(result.data)
      fetchNotificationSettings(result.data)
      setDisplayNames((prevState) => ({
        ...prevState,
        projects: names,
      }))
    } catch (err) {
      toaster({
        color: 'danger',
        title: 'Unable to Fetch Projects',
        text: err.message,
      })
    } finally {
      setLoadingProjects(false)
    }
  }, [fetchNotificationSettings, toaster])

  const fetchUseCases = useCallback(async () => {
    setLoadingUseCases(true)
    try {
      const result = await api.fetchUseCases()
      setUseCases(result.data)
      const names = buildDisplayNames(result.data)
      setDisplayNames((prevState) => ({
        ...prevState,
        useCases: names,
      }))
    } catch (err) {
      toaster({
        color: 'danger',
        title: 'Unable to Fetch Use Cases',
        text: err.message,
      })
    } finally {
      setLoadingUseCases(false)
    }
  }, [toaster])

  const fetchSenders = useCallback(async () => {
    setLoadingSenders(true)
    try {
      const result = await api.fetchSenders()
      setSenders(result.data)
      const names = buildDisplayNames(result.data)
      setDisplayNames((prevState) => ({
        ...prevState,
        senders: names,
      }))
    } catch (err) {
      toaster({
        color: 'danger',
        title: 'Unable to Fetch Senders',
        text: err.message,
      })
    } finally {
      setLoadingSenders(false)
    }
  }, [toaster])

  const fetchRecipients = useCallback(async () => {
    try {
      setLoadingRecipients(true)
      const result = await api.fetchRecipients()
      setRecipients(result.data)
      const names = buildDisplayNames(result.data)
      setDisplayNames((prevState) => ({
        ...prevState,
        recipients: names,
      }))
    } catch (err) {
      toaster({
        color: 'danger',
        title: 'Unable to Fetch Recipients',
        text: err.message,
      })
    } finally {
      setLoadingRecipients(false)
    }
  }, [toaster])

  const fetchLatestNotifications = useCallback(
    async (
      offset = notificationsPaginationLatest.offset,
      limit = notificationsPaginationLatest.limit,
      loadMore = false
    ) => {
      const date = moment().subtract(7, 'd').format('YYYY-MM-DD')
      try {
        const result = await api.fetchLatestNotifications(offset, limit, date)
        if (loadMore) {
          setLatestNotifications((prevNotifiations) => [
            ...prevNotifiations,
            ...result.data.notifications,
          ])
        } else {
          setLatestNotifications(result.data.notifications)
        }
        setNotificationsLoadedLatest(true)
        if (result.data.pagination) {
          setNotificationsPaginationLatest((prevState) => ({
            ...prevState,
            total: result.data.pagination.rowCount,
            offset: result.data.pagination.offset,
          }))
        }
      } catch (err) {
        toaster({
          color: 'danger',
          title: 'Unable to Fetch Notifications',
          text: err.message,
        })
      }
    },
    [toaster, notificationsPaginationLatest]
  )

  const fetchNotifications = useCallback(
    async (
      offset = notificationsPagination.offset,
      limit = notificationsPagination.limit,
      search = notificationsSearch,
      loadMore = false
    ) => {
      try {
        const result = await api.fetchNotifications(offset, limit, search)
        if (loadMore) {
          const mergedNotifications = await mergeGroupedNotifications(
            notifications,
            result.data.notifications
          )
          setNotifications(mergedNotifications)
        } else {
          setNotifications(result.data.notifications)
        }
        setNotificationsLoaded(true)
        if (result.data.pagination) {
          setNotificationsPagination((prevState) => ({
            ...prevState,
            total: result.data.pagination.rowCount,
            offset: result.data.pagination.offset,
          }))
        }
      } catch (err) {
        toaster({
          color: 'danger',
          title: 'Unable to Fetch Notifications',
          text: err.message,
        })
      }
    },
    [toaster, notificationsPagination, notificationsSearch]
  )

  async function readNotifications(ids) {
    if (!ids.length) return
    try {
      for (let i in ids) {
        await api.readNotification(ids[String(i)])
      }
      fetchNotifications()
      fetchLatestNotifications()
    } catch (err) {
      toaster({
        color: 'danger',
        title: 'Oops!',
        text: 'There was a problem saving your changes.',
      })
    }
  }

  const extractSenders = useCallback(() => {
    if (selectedSenders && selectedSenders.length) {
      return selectedSenders
    }
    return senders.reduce((agg, { name, parent }) => {
      if (!parent) {
        agg.push(name)
      }
      return agg
    }, [])
  }, [selectedSenders, senders])

  const extractSubsenders = useCallback(() => {
    const subsenders = []

    for (var sender of senders) {
      if (sender.parent) {
        // found a subsender
        if (
          selectedSenders.includes(sender.name) ||
          selectedSenders.length === 0
        ) {
          subsenders.push(sender.name)
        }
      }
    }
    return subsenders
  }, [selectedSenders, senders])

  const fetchMessages = useCallback(
    async ({ append = false } = {}) => {
      setLoadingMessages(true)
      if (!append) {
        setMessages([])
      }
      try {
        const senders = selectedSenders.length ? extractSenders() : []
        const subsenders = selectedSenders.length ? extractSubsenders() : []
        const data = {
          start: date[0],
          end: date[1],
          text: query,
          projects: selectedProjects,
          useCases: selectedUseCases,
          senders,
          subsenders,
          recipients: selectedRecipients,
          projectData: selectedProjectData,
          statuses: selectedStatuses,
          from: append ? messages.length : 0,
          environment: environment,
          sort: {
            [messagesTableSort.field]: messagesTableSort.direction,
          },
        }
        if (window.location.pathname === '/projects/ETOR') {
          data.eventTypes = selectedEventTypes
          data.warnings = selectedWarnings
        }
        const result = await api.fetchMessages(data)
        setMessages((prevMessages) => [...prevMessages, ...result.data])
        setMoreMessagesAvailable(!!result.data.length)
      } catch (err) {
        toaster({
          color: 'danger',
          title: 'Unable to Fetch Messages',
          text: err.message,
        })
      } finally {
        setLoadingMessages(false)
      }
    },
    [
      toaster,
      date,
      environment,
      extractSenders,
      extractSubsenders,
      messages,
      query,
      selectedProjectData,
      selectedStatuses,
      messagesTableSort,
      selectedProjects,
      selectedRecipients,
      selectedUseCases,
      selectedSenders,
      selectedEventTypes,
      selectedWarnings,
    ]
  )

  const fetchMessageCounts = useCallback(async () => {
    setLoadingMessageCounts(true)

    try {
      const senders = selectedSenders.length ? extractSenders() : []
      const subsenders = selectedSenders.length ? extractSubsenders() : []
      const data = {
        projects: selectedProjects,
        useCases: selectedUseCases,
        senders,
        subsenders,
        recipients: selectedRecipients,
        statuses: selectedStatuses,
        projectData: selectedProjectData,
        environment,
        date,
        query,
      }
      if (window.location.pathname === '/projects/ETOR') {
        data.eventTypes = selectedEventTypes
        data.warnings = selectedWarnings
      }
      const updatedCounts = await api.fetchMessageCounts(data)
      setMessageCounts((previous) => {
        if (previous.previous) {
          merge(previous, previous.previous)
          delete previous.previous
        }
        updatedCounts.previous = previous || {}
        return updatedCounts
      })
    } catch (err) {
      toaster({
        color: 'danger',
        title: 'Unable to Fetch Message Counts',
        text: err.message,
      })
    } finally {
      setLoadingMessageCounts(false)
    }
  }, [
    toaster,
    date,
    environment,
    extractSenders,
    extractSubsenders,
    query,
    selectedProjectData,
    selectedStatuses,
    selectedProjects,
    selectedRecipients,
    selectedUseCases,
    selectedSenders,
    selectedEventTypes,
    selectedWarnings,
  ])

  const fetchMessageCountsOverTime = useCallback(async () => {
    setLoadingMessageCountsOverTime(true)
    const sendersByName = senders.reduce((agg, sender) => {
      agg[sender.name] = sender
      return agg
    }, {})
    try {
      const data = {
        projects: selectedProjects,
        useCases: selectedUseCases,
        senders: selectedSenders.filter(
          (name) =>
            sendersByName[String(name)] && !sendersByName[String(name)].parent
        ),
        subsenders: selectedSenders.filter(
          (name) =>
            sendersByName[String(name)] && sendersByName[String(name)].parent
        ),
        recipients: selectedRecipients,
        statuses: selectedStatuses,
        projectData: selectedProjectData,
        sendersByName,
        environment,
        date,
        query,
      }
      if (window.location.pathname === '/projects/ETOR') {
        data.eventTypes = selectedEventTypes
        data.warnings = selectedWarnings
      }
      const updatedCountsOverTime = await api.fetchMessageCountsOverTime(data)
      setHighlightLines()
      setMessageCountsOverTime(updatedCountsOverTime)
    } catch (err) {
      toaster({
        color: 'danger',
        title: 'Unable to Fetch Graph Data',
        text: err.message,
      })
    } finally {
      setLoadingMessageCountsOverTime(false)
    }
  }, [
    toaster,
    date,
    environment,
    query,
    selectedProjectData,
    selectedStatuses,
    selectedProjects,
    selectedRecipients,
    selectedUseCases,
    selectedSenders,
    selectedEventTypes,
    selectedWarnings,
    senders,
    setHighlightLines,
  ])

  const fetchExports = useCallback(async () => {
    try {
      const exports = await api.fetchExports()
      setCsvExports(exports.data)
    } catch (err) {
      toaster({
        color: 'danger',
        title: 'Unable to fetch exports',
        text: err.message,
      })
    }
  }, [toaster])

  const fetchSupportTickets = useCallback(async () => {
    try {
      const resp = await api.fetchSupportTickets()
      setSupportTickets(resp.data)
    } catch (err) {
      toaster({
        color: 'danger',
        title: 'Unable to fetch support tickets.',
        text: err.message,
      })
    }
  }, [toaster])

  const fetchEventTypes = useCallback(async () => {
    setLoadingEventTypes(true)
    try {
      const result = await api.fetchEventTypes({ environment })
      setEventTypes(result.eventTypes)
      const names = buildDisplayNames(result.eventTypes) // needs to be specific to Event Types
      setDisplayNames((prevState) => ({
        ...prevState,
        eventTypes: names,
      }))
    } catch (err) {
      console.error(err)
      toaster({
        color: 'danger',
        title: 'Unable to Fetch Event Types',
        text: err.message,
      })
    } finally {
      setLoadingEventTypes(false)
    }
  }, [toaster, environment])

  const fetchGroups = useCallback(
    async (
      offset = groupsPagination.offset,
      limit = groupsPagination.limit,
      search = '',
      loadMore = false
    ) => {
      try {
        let result;
        if (keycloak.hasClientRole({ roleName: 'edit' })) {
          result = await api.fetchGroups({ offset, limit, search })
        } else if (keycloak.hasClientRole({ roleName: 'group-manager' })) {
          result = await api.fetchManagerGroups({ offset, limit, search })
        } else {
          return
        }
        if (loadMore) {
          setGroups((prevState) => [...prevState, ...result.data.groups])
        } else {
          setGroups(result.data.groups)
        }
        if (result.data.pagination) {
          setGroupsPagination((prevState) => ({
            ...prevState,
            total: result.data.pagination.rowCount,
            offset: result.data.pagination.offset,
          }))
        }
      } catch (err) {
        toaster({
          color: 'danger',
          title: 'Unable to fetch groups',
          text: err.message,
        })
      }
    },
    [toaster]
  )

  const fetchUserGroups = async () => {
    try {
      const result = await api.fetchUserGroups()
      if (!result.data?.length) return
      setUserGroups(result.data)
    } catch (err) {
      toaster({
        color: 'danger',
        title: 'Unable to fetch user groups',
        text: err.message,
      })
    }
  }

  const buildOfflineMap = (data) => {
    const result = data.reduce((acc, c) => {
      if (!acc[c.EntityType]) {
        acc[c.EntityType] = {}
      }
      acc[c.EntityType][c.EntityName] = { ...c }
      return acc
    }, {})
    return result
  }

  const fetchOffline = async () => {
    try {
      const resp = await api.fetchOffline()
      if (!resp.data) return
      setOfflineStatus(buildOfflineMap(resp.data))
    }
    catch (err) {
      console.log('Unable to fetch offline statuses', err)
    }
  }

  const fetchAll = useCallback(() => {
    const { offset, limit } = initialPagination
    if (keycloak.hasClientRole({ roleName: 'view' })) {
      fetchUsers(offset, limit)
      fetchTags()
    }
    fetchProjects()
    fetchUseCases()
    fetchSenders()
    fetchRecipients()
    fetchNotifications(offset, limit)
    fetchLatestNotifications(offset, limit)
    fetchExports()
    fetchSupportTickets()
    fetchOffline()
  }, [
    fetchProjects,
    fetchUseCases,
    fetchSenders,
    fetchRecipients,
    fetchNotifications,
    fetchLatestNotifications,
    fetchUsers,
    fetchTags,
    keycloak,
    fetchMessages,
    fetchMessageCounts,
    fetchMessageCountsOverTime,
    fetchExports,
    fetchSupportTickets,
    fetchOffline
  ])

  useEffect(() => {
    if (senders.length > 0 && isMainDashRoute) {
      fetchMessages()
      fetchMessageCounts()
      fetchMessageCountsOverTime()
    } else {
      return
    }
    // eslint-disable-next-line
  }, [senders, isMainDashRoute])

  useEffect(() => {
    if (!authenticated) {
      initKeycloak()
    } else {
      fetchAll()
    }
    // eslint-disable-next-line
  }, [authenticated])

  useEffect(() => {
    if (
      authenticated &&
      isMainDashRoute &&
      !loadingMessages &&
      !loadingMessageCounts &&
      !loadingMessageCountsOverTime
    ) {
      fetchMessages()
      fetchMessageCounts()
      fetchMessageCountsOverTime()
    }
    // eslint-disable-next-line
  }, [
    authenticated,
    query,
    date,
    selectedProjects,
    selectedSenders,
    selectedRecipients,
    selectedUseCases,
    selectedStatuses,
    selectedProjectData,
    selectedEventTypes,
    selectedWarnings,
    environment,
    isMainDashRoute,
  ])

  useEffect(() => {
    if (authenticated && isMainDashRoute) {
      fetchMessages()
    }
    // eslint-disable-next-line
  }, [messagesTableSort, isMainDashRoute])

  const updateQuery = (q) => {
    setQuery(q)
  }

  const refresh = async () => {
    fetchAll()
  }

  const updateSelectedStatuses = (statuses) => {
    setselectedStatuses(uniq(statuses))
  }

  const updateSelectedProjects = (projects) => {
    setSelectedProjects(projects)
    if (projects.length === 0) {
      setProjectsChecked(false)
    } else {
      setProjectsChecked(true)
    }
  }

  const updateSelectedUseCases = (useCases) => {
    setSelectedUseCases(useCases)
    if (useCases.length === 0) {
      setUseCasesChecked(false)
    } else {
      setUseCasesChecked(true)
    }
  }

  const updateSelectedRecipients = (recipients) => {
    setSelectedRecipients(recipients)
    if (recipients.length === 0) {
      setRecipientsChecked(false)
    } else {
      setRecipientsChecked(true)
    }
  }

  const updateSelectedSenders = (senders) => {
    setSelectedSenders(senders)
    if (senders.length === 0) {
      setSendersChecked(false)
    } else {
      setSendersChecked(true)
    }
  }

  const updateSelectedEventTypes = (eventTypes) => {
    setSelectedEventTypes(eventTypes)
    if (eventTypes.length === 0) {
      setEventTypesChecked(false)
    } else {
      setEventTypesChecked(true)
    }
  }
  
  const updateSelectedWarnings = (warnings) => {
    setSelectedWarnings(warnings)
    if (warnings.length === 0) {
      setWarningsChecked(false)
    } else {
      setWarningsChecked(true)
    }
  }

  const onChangeProjectsSwitch = (e) => {
    setProjectsChecked(e.target.checked)
    if (e.target.checked) {
      let projectNames = []
      for (var project of projects) {
        projectNames.push(project.name)
      }
      updateSelectedProjects(projectNames)
    } else {
      updateSelectedProjects([])
    }
  }

  const onChangeUseCasesSwitch = (e) => {
    setUseCasesChecked(e.target.checked)
    if (e.target.checked) {
      let useCasesNames = []
      for (var useCase of useCases) {
        useCasesNames.push(useCase.name)
      }
      updateSelectedUseCases(useCasesNames)
    } else {
      updateSelectedUseCases([])
    }
  }

  const onChangeSendersSwitch = (e) => {
    setSendersChecked(e.target.checked)
    if (e.target.checked) {
      let senderNames = []
      for (var sender of senders) {
        senderNames.push(sender.name)
      }
      updateSelectedSenders(senderNames)
    } else {
      updateSelectedSenders([])
    }
  }

  const onChangeRecipientsSwitch = (e) => {
    setRecipientsChecked(e.target.checked)
    if (e.target.checked) {
      let recipientNames = []
      for (var project of recipients) {
        recipientNames.push(project.name)
      }
      updateSelectedRecipients(recipientNames)
    } else {
      updateSelectedRecipients([])
    }
  }

  const onChangeEventTypesSwitch = (e) => {
    setEventTypesChecked(e.target.checked)
    if (e.target.checked) {
      let eventTypeNames = []
      for (var eventType of eventTypes) {
        eventTypeNames.push(eventType.name)
      }
      updateSelectedEventTypes(eventTypeNames)
    } else {
      updateSelectedEventTypes([])
    }
  }
  
  const onChangeWarningsSwitch = (e) => {
    setWarningsChecked(e.target.checked)
    if (e.target.checked) {
      let warningNames = []
      for (var warning of warnings) {
        warningNames.push(warning.name)
      }
      updateSelectedWarnings(warningNames)
    } else {
      updateSelectedWarnings([])
    }
  }

  const loadNewMessages = () => {
    fetchMessages({ append: true })
  }

  const updateEnvironment = (environment) => {
    setEnvironment(environment)
  }

  const generateSenderTree = (senders) => {
    let senderTree = []

    senders.forEach(
      ({ name, parent, count, isCurrent, status, description }) => {
        if (!parent) {
          senderTree.push({
            name: name,
            count: count,
            children: [],
            isCurrent: isCurrent,
            status: status,
            description: description,
            parent: null,
          })
        }
      }
    )

    senders.forEach(
      ({ name, parent, count, isCurrent, status, description }) => {
        if (parent) {
          var index = senderTree.findIndex((sender) => sender.name === parent)
          if (index !== -1) {
            // parent sender listed in senders meaning user has access to it
            senderTree[String(index)].count = count
            senderTree[String(index)].children.push({
              name: name,
              count: count,
              isCurrent: isCurrent,
              status: status,
              description: description,
              parent: parent,
            })
          } else {
            // sender has a parent (aka a subsender) but it is not listed in the senders data (aka not allowed to see it)
            senderTree.push({
              name: name,
              count: count,
              children: [],
              isCurrent: isCurrent,
              status: status,
              description: description,
              parent: parent,
            })
          }
        }
      }
    )

    return senderTree
  }

  const updateNotificationsPagination = (key, newValue) => {
    let newPagination = { ...notificationsPagination }
    newPagination[String(key)] = newValue
    setNotificationsPagination(newPagination)
  }

  const updateNotificationsPaginationLatest = (key, newValue) => {
    let newPagination = { ...notificationsPaginationLatest }
    newPagination[String(key)] = newValue
    setNotificationsPaginationLatest(newPagination)
  }

  const selectNotificationsTable = (itemId) => {
    const selectedMap = { ...notificationsTableIdMap }
    if (selectedMap[String(itemId)]) {
      delete selectedMap[String(itemId)]
    } else {
      selectedMap[String(itemId)] = true
    }
    setNotificationsTableIdMap(selectedMap)
  }

  const openWs = useCallback(() => {
    if (ws) return
    try {
      const wsConn = new WebSocket(`${wsHost}?token=${keycloak.token}`)
      wsConn.onopen = () => {
        // console.log('Websocket open event >> ', event)
      }
      wsConn.onmessage = (event) => {
        // console.log('Websocket message event >> ', event)
        const data = JSON.parse(event.data)
        const { offset, limit } = initialPagination
        if (data.action && data.action === 'update-csv-export') {
          fetchExports()
          fetchNotifications(offset, limit)
          fetchLatestNotifications(offset, limit)
        }
        if (data.action && data.action === 'update-notification') {
          let message = 'You have a new notification'
          let title = 'New Notification'
          const notiType = data.payload?.type
          if (notiType) {
            if (notificationTypeMap[String(notiType)].pushText) {
              message = notificationTypeMap[String(notiType)].pushText
            }
          }
          const notiProject = data.payload?.project
          if (notiProject && notiProject !== 'general') {
            title = notiProject
          }
          const notification = new Notification(title, {
            body: message,
          })
          notification.onclick = () => window.focus()
          fetchNotifications(offset, limit)
          fetchLatestNotifications(offset, limit)
        }
      }
      wsConn.onclose = (event) => {
        // console.log('Websocket close event >> ', event)
        /*
          Close codes:
          1001 - Going away
        */
        if (event.code === 1001) {
          setWs(null)
        }
      }
      wsConn.onerror = (event) => {
        console.log('Websocket error event >> ', event)
      }
      setWs(wsConn)
    } catch (err) {
      console.log('Error opening websocket connection >> ', err)
    }
  }, [ws, keycloak])

  const closeWs = () => {
    if (ws) {
      ws.close()
      setWs(null)
    }
  }

  const checkExportStatuses = (exports) => {
    // Keep ws open for following statuses
    const validStatuses = ['SUBMITTED', 'RUNNING']
    for (let { status } of exports) {
      if (validStatuses.includes(status)) {
        return true
      }
    }
    return false
  }

  const checkPushNotiPermission = () => {
    try {
      if (!('Notification' in window)) {
        return false
      }
      if (
        keycloak &&
        keycloak.hasClientRole({ roleName: 'notifications-tester' })
      ) {
        if (Notification.permission === 'granted') {
          return true
        }
      }
      return false
    } catch {
      return false
    }
  }

  useEffect(() => {
    if (!keycloak.token) return
    const wsExport = checkExportStatuses(csvExports)
    if (wsExport) {
      openWs()
      return
    }
    const wsNoti = checkPushNotiPermission()
    if (wsNoti) {
      openWs()
      return
    }
    closeWs()
  }, [keycloak, ws, csvExports])

  useEffect(() => {
    if (!keycloak.token) return
    if (!('Notification' in window)) {
      return
    }
    if (keycloak.hasClientRole({ roleName: 'notifications-tester' })) {
      if (!initPushNoti) {
        toaster({
          color: 'success',
          title: 'You can now receive push notifications in Dash',
        })
        setInitPushNoti(true)
      }
      if (Notification.permission === 'default') {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            openWs()
          }
        })
      }
    }
  }, [keycloak])

  const value = {
    environment,
    packageInfo,
    keycloak,
    projects,
    useCases,
    notifications,
    setNotifications,
    messages,
    senders,
    senderTree: generateSenderTree(senders),
    recipients,
    eventTypes,
    warnings,
    tags,
    setTags,
    users,
    setUsers,
    toaster,
    date,
    query,
    extractSenders,
    extractSubsenders,
    refresh,
    displayNames,
    loadingMessages,
    loadingProjects,
    loadingUseCases,
    loadingSenders,
    loadingRecipients,
    loadingEventTypes,
    messageCountsOverTime,
    loadingMessageCountsOverTime,
    selectedStatuses,
    selectedProjects,
    selectedUseCases,
    selectedSenders,
    selectedRecipients,
    selectedEventTypes,
    selectedWarnings,
    updateSelectedStatuses,
    updateSelectedProjects,
    updateSelectedUseCases,
    updateSelectedRecipients,
    updateSelectedSenders,
    updateSelectedEventTypes,
    updateSelectedWarnings,
    projectsChecked,
    onChangeProjectsSwitch,
    useCasesChecked,
    onChangeUseCasesSwitch,
    sendersChecked,
    onChangeSendersSwitch,
    recipientsChecked,
    onChangeRecipientsSwitch,
    eventTypesChecked,
    onChangeEventTypesSwitch,
    warningsChecked,
    onChangeWarningsSwitch,
    messageCounts,
    loadNewMessages,
    moreMessagesAvailable,
    loadingMessageCounts,
    highlightLines,
    setHighlightLines,
    entityTableSorts,
    setEntityTableSorts,
    messagesTableSort,
    setMessagesTableSort,
    selectedProjectData,
    setSelectedProjectData,
    notificationsInitialSelected,
    setNotificationsInitialSelected,
    notificationSettingsInitialOpen,
    setNotificationSettingsInitialOpen,
    showNotificationSettings,
    setShowNotificationSettings,
    notificationsPagination,
    notificationsPaginationLatest,
    setNotificationsSearch,
    updateNotificationsPagination,
    updateNotificationsPaginationLatest,
    fetchNotifications,
    fetchLatestNotifications,
    fetchNotificationSettings,
    notificationsTableIdMap,
    setNotificationsTableIdMap,
    handleNotificationPopout,
    selectNotificationsTable,
    notificationsLoaded,
    notificationsLoadedLatest,
    notificationSettings,
    notificationSettingsGlobal,
    readNotifications,
    latestNotifications,
    setLatestNotifications,
    initialPagination,
    userGuideUrl,
    navBarExpanded,
    setNavBarExpanded,
    exportToolExpanded,
    setExportToolExpanded,
    supportToolExpanded,
    setSupportToolExpanded,
    showToolBar,
    setShowToolBar,
    offlineStatus,
    fetchOffline,
    csvExports,
    fetchExports,
    supportTickets,
    setSupportTickets,
    fetchSupportTickets,
    notificationInitialSelect,
    setNotificationInitialSelect,
    fetchUsers,
    usersPagination,
    setUsersPagination,
    tagsPagination,
    setTagsPagination,
    fetchTags,
    fetchEventTypes,
    setUsersLastQtr,
    usersLastQtr,
    groups,
    setGroups,
    userGroups,
    fetchGroups,
    fetchUserGroups,
    groupsPagination,
    setGroupsPagination,
    ws,
    setWs,
    openWs,
    closeWs,
    checkPushNotiPermission,
    clearFilters,
    clearMessages,
    authenticated,
    setMessageCounts,
    setLoadingMessageCounts,
    setMessages,
    setMoreMessagesAvailable,
    setMessageCountsOverTime,
  }

  const renderAdminRoutes = () => {
    if (!keycloak) return
    const routes = []
    if (keycloak.hasClientRole({ roleName: 'view' })) {
      routes.push(
        <Route path='/admin' key='admin-routes-0'>
          <AdminPage />
        </Route>
      )
    }
    if (keycloak.hasClientRole({ roleName: 'onboard' })) {
      routes.push(
        <Route path='/settings' key='admin-routes-1'>
          <SettingsPage />
        </Route>
      )
    }
    return routes
  }

  const renderGroupsRoute = () => {
    if (!keycloak) return
    let route = null
    if (keycloak.hasClientRole({ roleName: 'view' }) || keycloak.hasClientRole({ roleName: 'group-manager' })) {
      route = (
        <Route path='/groups' key='groups-route-0'>
          <GroupsPage />
        </Route>
      )
    }
    return route
  }

  const updateDate = (value) => {
    window.localStorage.setItem('defaultDate', JSON.stringify(value))
    setDate(value)
  }

  return (
    <ErrorBoundary fallback={<ErrorMessage />}>
      <AppContext.Provider value={value}>
        <div className='App'>
          {authenticated ? (
            <React.Fragment>
              <Header
                keycloak={keycloak}
                notifications={notifications}
                query={query}
                updateQuery={updateQuery}
                date={date}
                updateDate={updateDate}
                refresh={refresh}
                environment={environment}
                updateEnvironment={updateEnvironment}
                userGuideUrl={userGuideUrl}
              />
              <div
                className='app-container'
                style={{ marginRight: showToolBar ? '48px' : '0' }}
              >
                <NavBar
                  expanded={navBarExpanded}
                  setNavBarExpanded={setNavBarExpanded}
                  clearFilters={clearFilters}
                  keycloak={keycloak}
                />
                <ToolBar />
                <Switch>
                  <Route exact path={['/', '/projects/:projectName']}>
                    <DashboardSwitch />
                  </Route>
                  <Route exact path='/projects'>
                    <ProjectsPage />
                  </Route>
                  <Route path='/notifications'>
                    <NotificationsPage />
                  </Route>
                  {renderAdminRoutes()}
                  {renderGroupsRoute()}
                </Switch>
              </div>
            </React.Fragment>
          ) : (
            <></>
          )}
          <EuiGlobalToastList
            toasts={toasts}
            dismissToast={dismissToast}
            toastLifeTimeMs={7000}
            style={{ textAlign: 'left' }}
          />
        </div>
      </AppContext.Provider>
    </ErrorBoundary>
  )
}

export default App
