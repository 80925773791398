import Keycloak from 'keycloak-js'

const config = {}
config.url =
  process.env.REACT_APP_KEYCLOAK_URL ||
  config.url ||
  'http://localhost:8080/auth/'

config.realm = process.env.REACT_APP_KEYCLOAK_REALM || 'smns'
config.clientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID || 'smns-app'

const keycloak = new Keycloak(config)

keycloak.hasClientRole = function ({ roleName, clientId = config.clientId }) {
  const clientAccess =
    this.tokenParsed && this.tokenParsed.resource_access[String(clientId)]
  return !!(clientAccess && clientAccess.roles.includes(roleName))
}

keycloak.getUserFullName = () => {
  if (!(keycloak && keycloak.tokenParsed)) {
    return "Unknown User";
  }
  return `${keycloak.tokenParsed.given_name} ${keycloak.tokenParsed.family_name}`
}

export default keycloak
